export class Address {
  readonly "@id"?: string;
  readonly "@type"?: string;
  readonly "@context"?: string;
  readonly 'id'?:number
  googlePlaceId: string;
  sessionToken?: string;
  streetNumber: string;

  streetName: string;
  city: string;
  department: string;
  region: string;
  country: string;
  zip: string;
  latitude: string;
  longitude?: string;
  postGISPoint?: string;
  url: string;
  formattedAddress: string;
  houseCode?: string;
  intercom?: string;
  extraInformation?: string;
  isDefault: boolean;
  owner: string | any;
  punctualCourses?: string[];
  lastModificationDatetime?: string;
  creationDatetime?: string;
  isDeleted?: boolean;

  constructor(
    googlePlaceId: string,
    streetNumber: string,
    streetName: string,
    city: string,
    department: string,
    region: string,
    country: string,
    zip: string,
    latitude: string,
    url: string,
    formattedAddress: string,
    owner: string,
    isDefault: boolean,
    houseCode?: string,
    intercom?: string,
    extraInformation?: string,
  ) {
     this.googlePlaceId = googlePlaceId;
    this.streetNumber = streetNumber;
    this.streetName = streetName;
    this.city = city;
    this.department = department;
    this.region = region;
    this.country = country;
    this.zip = zip;
    this.latitude = latitude;
    this.url = url;
    this.formattedAddress = formattedAddress;
    this.isDefault = isDefault;
    this.owner = owner;
    this.houseCode = houseCode;
    this.intercom =intercom;
    this.extraInformation= extraInformation;
  }

  formatAddress(): string {
    return (
      this.streetNumber +
      " " +
      this.streetName +
      "\n" +
      this.zip +
      " " +
      this.city
    );
  }
}
